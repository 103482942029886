import React, { useState, useEffect } from "react";
import * as PropTypes from "prop-types";
import { Layout, Breadcrumb, Grid } from "antd";
import { withAuthorized } from "hoc/Authorized";
import LoadingSpinner from "../LoadingSpinner";
import Header from "./Header";
import Breadcrumbs from "react-router-dynamic-breadcrumbs";
import styled from "styled-components";
import SideMenu from "./SideMenu";
import store from "../../store";
import { logoutUser } from "../../redux/auth";
// import useWindowSize from './windowHook'
import withSizes from "react-sizes";
import { withTranslation, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const routesList = {
  "/": "caseNames.homePage",
  "/hydrological-monitoring": "caseNames.hydrologicalMonitoring",
  "/meteorological-monitoring": "caseNames.meteorologicalMonitoring",
  "/weather-forecast": "caseNames.weatherForecast",
  "/buisnesMap": "caseNames.buisnesMap",
  "/plan-SER": "caseNames.comprehensivePlanSER",
  "/dtp": "caseNames.dtp",
  "/crime": "caseNames.criminalSituation",
  "/population": "caseNames.population",
  "/road-construction": "caseNames.repairAndConstruction",
  "/ddo": "caseNames.distributionOfChildrenInKindergartens",
  "/social": "caseNames.social",
  "/new-appeals": "caseNames.newAppeals",
  "/appeals-monitoring": "caseNames.appealsMonitoring",
  "/category-analytics": "caseNames.categoryAnalytics",
  "/air-pollution": "caseNames.airPollution",
  "/pedestrian-flow": "caseNames.pedestrianFlow",
  "/car-flow": "caseNames.carFlow",
  "/school": "caseNames.school",
  "/damu-med": "caseNames.morbidityData",
  "/analytical-indicators": "Аналитические индикаторы",
  "/video-streams": "caseNames.videoStreams",
  "/monitoring-state": "caseNames.monitoringState",
  "/business-map": "caseNames.businessCard",
  "/income": "caseNames.income",
  "/outcome": "caseNames.costs",
  "/error": "caseNames.error",
  "/cameramap": "Карта камер",
  "/infobus": "caseNames.infobus",
  "/monitoring-smi": "caseNames.monitoringSmi",
  "/expensesWithoutSavings": "caseNames.expensesWithoutSavings",
  "/IncomeWithoutAccumulation": "caseNames.IncomeWithoutAccumulation",
  "/e-Otinish": "e-Otinish",
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
  width: width,
});

export default withTranslation()(
  withSizes(mapSizesToProps)(
    withAuthorized(function MainLayout({
      component: ComponentLayout,
      title,
      ...rest
    }) {
      const [menu, setMenu] = useState(false);
      // const size = useWindowSize();

      const toggleSideMenu = (val) => {
        setMenu(val);
      };
      const logout = () => {
        store.dispatch(logoutUser());
      };

      const { t, i18n } = useTranslation();
      return (
        <Layout>
          {/* <VerticalHeader toggleSideMenu={toggleSideMenu} showMenu={menu} /> */}
          <SideMenu toggleSideMenu={toggleSideMenu} showMenu={menu} {...rest} />
          <Layout>
            <Header toggleSideMenu={toggleSideMenu} showMenu={menu} {...rest} />
            <Box>
              <Wrapper>
                <Headline>
                  <Breadcrumbs
                    mappedRoutes={routesList}
                    WrapperComponent={(props) => (
                      <CustomBread>{props.children}</CustomBread>
                    )}
                    ActiveLinkComponent={(props) => {
                      return (
                        <Breadcrumb.Item>{t(props.children)}</Breadcrumb.Item>
                      );
                    }}
                    LinkComponent={(props) => {
                      const {
                        children: { props: childrenProps },
                      } = props;
                      const { to, children: text } = childrenProps;

                      return (
                        <Breadcrumb.Item>
                          <Link to={to}>{t(text)}</Link>
                        </Breadcrumb.Item>
                      );
                    }}
                  />
                  <Title>{title}</Title>
                </Headline>
                <Content>
                  <React.Suspense fallback={<LoadingSpinner loading={true} />}>
                    <ComponentLayout {...rest} />
                  </React.Suspense>
                </Content>
              </Wrapper>
            </Box>
          </Layout>
        </Layout>
      );
    })
  )
);

let CustomBread = styled(Breadcrumb)`
  a,
  span:last-child {
    color: #fff;
  }
`;

const Box = styled(Layout.Content)`
  padding: 20px;
`;

let Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #24262f;
  border-radius: 8px;
  overflow: hidden;
`;

let Title = styled.div`
  font-size: 2.25rem;
  color: #ffffff;
  margin-top: 10px;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

let Headline = styled.div`
  background: #2a2c36;
  padding: 20px 30px;
`;

let Content = styled.div`
  overflow: auto;
  flex-grow: 1;
  padding: 20px;
`;
