import React from "react";

export const PlanSER = () => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        border: "1px solid gray",
      }}
      title="Monitoring state"
      src="https://bicloud.smartkokshetau.kz/public/dashboards/33"
    ></iframe>
  );
};
