import React from "react";

export default function () {
  return (
    <div className="row-container">
      <iframe
        title="Business map"
        src="https://app.powerbi.com/view?r=eyJrIjoiYmU2ODhhNWQtODE3MS00YzNjLWIxZTgtMjIwNDhlMDhiOGZkIiwidCI6ImZkYTc1ZGJiLTc5MjUtNGM5Ny1hM2NhLTNiZDJmZDU0OWU5MCIsImMiOjl9"
        className="second-row"
      ></iframe>
    </div>
  );
}
