const Kz = {
  translation: {
    component: {
      selectDate: "Күнді таңдаңыз",
      export: "Экспорт",
      to: "дейін",
      selectCamera: "Камераны таңдаңыз",
    },
    month: {
      январь: "қаңтар",
      февраль: "ақпан",
      март: "наурыз",
      апрель: "сәуір",
      май: "мамыр",
      июнь: "маусым",
      июль: "шілде",
      август: "тамыз",
      сентябрь: "қыркүйек",
      октябрь: "қазан",
      ноябрь: "қараша",
      декабрь: "желтоқсан",
    },
    caseNames: {
      comprehensivePlanSER: "СЭР кешенді жоспары",
      expensesWithoutSavings: "Шығындар",
      IncomeWithoutAccumulation: "Кірістер",
      crimeScene: "Қылмыс орны",
      hydrologicalMonitoring: "Гидрологиялық мониторинг",
      meteorologicalMonitoring: "Метеорологиялық мониторинг",
      weatherForecast: "Ауа райы болжамы",
      forPeriod: "Кезең үшін",
      incident: "Шағым",
      informationRequest: "Сұраныс",
      inquiry: "Мәлімдеме",
      sinceLaunch: "Іске қосылған сәттен бастап",
      table: "Кесте",
      predict: "Болжам жасау",
      resetParameters: "Параметрлерді қалпына келтіру",
      change_of_deviation: "Отклону өзгерту",
      used_for_calculating_error_value:
        "Қателік мәнін есептеу үшін пайдаланылады.",
      changes_in_girls_birth_rate:
        "Қыздардың туу көрсеткішіндегі өзгерістер келесі жылдарда.",
      girls_birth_rate_coefficient:
        "Қыздардың туу коэффициенті, 1000 әйелге туған қыздардың саны.",
      changes_in_boys_birth_rate: "Келесі жылдардағы ұлдардың тууын өзгерту.",
      boys_birth_rate_coefficient:
        "Ұлдардың туу коэффициенті, 1000 әйелге туған ұлдардың саны.",
      migration_balance_change_years:
        "Келесі жылдардағы миграция сальдосының өзгеруі.",
      population_difference:
        "Келген және кеткен адамдардың саны арасындағы айырма. Мағынасына байланысты халық санына пропорционалды әсер.",
      changes_male_mortality_rate:
        "Ерлердің өлім-жітім коэффициентінің өзгеруі келесі жылдарда",
      female_mortality_rate:
        "Әйелдердің өлім-жітім коэффициенті, 1000 адамға шаққандағы қайтыс болғандар саны",
      changes_in_male_mortality_rate:
        "Ерлердің өлім-жітім коэффициентінің өзгерістері келесі жылдарда",
      male_mortality_rate:
        "Ерлердің өлім-жітім коэффициенті, 1000 адамға шаққанда өлгендер саны",
      male_mortality_change: "Ерлердің өлім-жітімінің өзгерісі",
      female_mortality_per_1000:
        "1000 адамға шаққандағы әйелдердің өлім-жітімі",
      female_mortality_change: "Әйелдердің өлім-жітімінің өзгерісі",
      migration_balance: "Миграция сальдосы",
      migration_balance_change: "Миграция сальдосының өзгерісі",
      birth_rate_boys: "Туу көрсеткіші (ұлдар)",
      birth_rate_boys_change: "Туу көрсеткішінің өзгерісі (ұлдар)",
      birth_rate_girls: "Туу көрсеткіші (қыздар)",
      birth_rate_girls_change: "Туу көрсеткішінің өзгерісі (қыздар)",
      newborn_mortality_boys: "Нәрестелердің өлім-жітімі (ұлдар)",
      newborn_mortality_girls: "Нәрестелердің өлім-жітімі (қыздар)",
      male_mortality_per_1000:
        "1000 адамға шаққандағы ер адамдардың өлім-жітімі",
      forecasting: "Болжамдау",
      populationForecasting: "Халық санының болжамы",
      "Умышленное причинение легкого вреда здоровью":
        "Денсаулыққа қасақана жеңіл зиян келтіру",
      Хулиганство: "Бұзақылық",
      Мошенничество: "Алаяқтық",
      "Незаконное культивирование запрещенных к возделыванию растений":
        "Есірткі заттарын қамтитын тыйым салынған өсімдіктерді заңсыз өсіру",
      Кража: "Ұрлық",
      "Доведение до самоубийства": "Өзін-өзі өлтіруге итермелеу",
      "Умышленное причинение средней тяжести вреда здоровью":
        "Денсаулыққа қасақана орташа ауырлықтағы зиян келтіру",
      "Незаконное обращение с наркотическими средствами":
        "Есірткі заттарымен заңсыз айналысу",
      Грабеж: "Тонау",
      school: "Мектеп",
      dtp: "ЖКО",
      ecology: "Экология",
      publicSafety: "Қоғамдық қауіпсіздік",
      criminalSituation: "Криминогендік жағдай",
      forecast: "Болжам жасау",
      population: "Халық саны",
      economy: "Экономика",
      businessCard: "Бизнес картасы",
      roadTrafficAccidents: "Жол-көлік оқиғалары",
      income: "Жинақталған кірістер",
      costs: "Жинақтау шығындары",
      healthCare: "Денсаулық сақтау",
      morbidityData: "Ауру деректері (DamuMed)",
      infrastructure: "Инфрақұрылым",
      buisnesMap: "Визит карточкасы",
      repairAndConstruction:
        "Инфрақұрылымдық желілерді жөндеу және салу жоспары",
      education: "Білім",
      social: "Азаматтардың өтініштері",
      categoryAnalytics: "Санатты талдау",
      airPollution: "Ауа зияндылығы",
      analyticalIndicators: "Аналитикалық белгілер",
      videoStreams: "Бейнемазмұндар",
      pedestrianFlow: "Жаяу жүргіншілер",
      carFlow: "Көлік жүргіншілері",
      newAppeals: "Работа Open City",
      appealsMonitoring: "Мониторинг өтініштер",
      monitoringState: "Мемлекеттік сатып алу мониторингі",
      distributionOfChildrenInKindergartens: "Балаларды балабақшаларға бөлу",
      distributionOfPupilsBySchool: "Оқушыларды мектептер бойынша бөлу",
      homePage: "Негізгі парақша",
      error: "Kіру қатесі",
      infobus: "Общественный транспорт",
      monitoringSmi: "Мониторинг СМИ",
    },
    error: {
      doNotHaveAccessToViewThisModule: "Сізде бұл модульді көруге рұқсат жоқ",
    },

    loginPage: {
      information: "Жылыой ауданының ахуалдық орталығы",
    },

    mainPage: {
      productionVolume: "Өнім көлемі (тауарлар, қызметтер)",
      productionVolumeBillion: "Өнім көлемі (тауарлар, қызметтер) млрд теңге",
      physicalVolumeIndexByMonths: "Айлар бойынша нақты көлем индексі (%)",
      notDefined: "Анықталмаған",
      robbery: "Тонау",
      GKPErementau: "Ақмола облысы УЗ «Ерейментау РБ» РВК жанындағы ГКП",
      GKPBuldanskaya: "Ақмола облысы УЗ «Буландынская РБ» РВК жанындағы ГКП",
      GKPAtbasarskaya: "Ақмола облысы УЗ «Атбасарская ММБ» РВК жанындағы ГКП",
      zerendinskayaCRB: "Зеренді орталық аудандық ауруханасы",
      hooliganism: "Бұзақылық",
      theft: "Ұрлық",
      murder: "Кісі өлтіру",
      fraud: "Алаяқтық",
      inducement_to_suicide: "Өз-өзіне қол жұмсауға итермелеу",
      intentional_injury: "Денсаулыққа қасақана орташа ауырлықта зиян келтіру",
      illegal_drug_handling:
        "Есірткі заттармен, психотроптық заттармен, олардың аналогтарымен, прекурсорлармен заңсыз айналысу",
      city: "Құлсары қ.",
      information: "Жылыой ауданының ахуалдық орталығы",
      profile: "Сіздің профиліңіз",
      exit: "Шығу",
      main: "Негізгі парақша",
      statisticalPanel: "Статистикалық панель",
      trafficAccidentsByDistrict: "Аудандар бойынша ЖКО",
      crimeCategory: "Қылмыс санаты",
      schoolPopulationStructure: "Мектептердің толымдылық құрылымы",
      numberOfVisitsToTheEmergencyRoom:
        "Қабылдау бөліміне түскен өтініштер саны",
      fullnessStructure: "Толымдылық құрылымы",
      numberOfSchools: "Мектептер саны",
      total: "Жалпы саны",
      projectCapacity: "Жобалық қуаты",
      surplus: "Артық",
      norm: "Норма",
      deficit: "Тапшылық",
      export: "Экспорт",
    },
    sideMenu: {
      menu: "Мәзір",
      publicSafety: "Қоғамдық қауіпсіздік",
      dtp: "ЖКО (Жол-көлік оқиғалары)",
      criminalSituatuin: "Криминогендік жағдай",
      economy: "Экономика",
      businessCard: "Бизнес картасы",
      income: "Кірістер",
      costs: "Шығыстар",
      healthCare: "Денсаулық сақтау",
      damumed: "Ауру деректері (DamuMed)",
      infastructure: "Инфрақұрылым",
      repairAndConstruction:
        "Инфрақұрылымдық желілерді жөндеу және салу жоспары",
      education: "Білім",
      distributionOfChildrenInKindergartens: "Балаларды балабақшаларға бөлу",
      distributionOfPupilsBySchool: "Оқушыларды мектептер бойынша бөлу",
      newAppeals: "Работа Open City",
      appealsMonitoring: "Мониторинг өтініштер",
      monitoringState: "Мемлекеттік сатып алу мониторингі",
      categoryAnalytics: "Аналитика категорий",
      social: "Азаматтардың өтініштері",
      airPollution: "Ауа зияндылығы",
      analyticalIndicators: "Аналитикалық белгілер",
      carFlow: "Көлік жүргіншілері",
      pedestrianFlow: "Жаяу жүргіншілер",
      videoStreams: "Видеопотоки",
      infobus: "Общественный транспорт",
      monitoringSmi: "Мониторинг СМИ",
      transport: "Көлік",
      routeSchedule: "Маршрут кестесі",
    },
    publicSafety: {
      dtp: {
        homePage: "Негізгі парақша ",
        involvingPublicTransport: " Қоғамдық көліктің қатысуымен",
        withTheParticipationOfPedestrian: "Жаяу жүргіншінің қатысуымен",
        intoxicated: "Алкогольдік масаңдықпен",
        accidentCard: "ЖКО карточкасы",
        violation: "Бұзушылық",
        town: "Қала",
        date: "Күні",
        lighting: "Жарықтандыру",
        driverCondition: "Жүргізушінің жағдайы",
        culprit: "Кінәлі",
        timesOfDay: "Тәулік уақыты",
        violationCategory: "Бұзушылық санаты",
        areas: "Апат болған жер",
        comparitiveAnalysisOfRoadAccidentsByMonth:
          "Айлар бойынша ЖКО салыстырмалы талдау",
        numberOfAccidents: "ЖКО саны ",
        weather: "Ауа-райы жағдайы",
        dusk: "Ымырт",
        day: "Күні",
        night: "Түн",
        total: "Жалпы саны",
      },
      crime: {
        area: "Аудан",
        category: "Санаты",
        numberOfCrimes: "Қылмыстар саны",
        crimeCard: "Қылмыс карточкасы",
        severity: "Ауырлық",
        article: "Бап",
        dateOfUD: "ҚІ (қылмыстық іс) жасалған күні",
        dateOfInitiationOfUD: "ҚІ (қылмыстық іс) қозғау күні",
        KUI: "АЕАК № (ақпаратты есепке алу кітабы)",
        authority: "Орган (Полиция бөлімі, Полиция басқармасы)",
        street: "Көше",
        house: "Үй",
        regions: "Аймақтар",
        region: "Аймақ",
        registrationAuthority: "Тіркеу органы",
        crimeCategory: "Қылмыс санаты",
      },
    },
    economy: {
      businessCard: {
        status: "Мәртебесі",
        dateOfUpdate: "Мәртебесі",
        startDate: "Бастапқы күні",
        finalDate: "Соңғы күні",
        registrationDate: "Тіркелген күні",
        BIN: "БСН (бизнес-сәйкестендіру нөмірі)",
        activity: "Белсенділігі",
        typeOfLegalUnit: "Құқықтық бірліктің түрі",
        area: "Аудан",
        OKED: "ЭҚЖЖ (Экономикалық қызметтің жалпы жіктеуіші)",
        taxDebtors: "Салық борышкерлері",
        taxCodeViolators: "Салық кодексін бұзушылар",
        inactiveEnterprises: "Белсенді емес кәсіпорындар",
        incorrectlyRegistered: "Қате тіркелгендер",
        wrongAddress: "Қате мекенжай",
        bankrupts: "Банкроттар",
        pseudoEnterprise: "Жалған кәсіпорындар",
        totalEnterprises: "Барлық кәсіпорындар",
        typeOfLegalUnits: "Құқықтық бірліктердің түрі",
        totalDistricts: "Барлық аудандар",
        totalOKED: "ЭҚЖЖ барлығы",
        totalRegisteredOrganizations: "Барлық тіркелген ұйымдар",
        totalOperatingOrganizations: "Жұмыс істеп тұрған барлық ұйымдар",
        numberOfOrganizationsByType: "Түрі бойынша ұйым саны",
        numberOfOKEDByTypeOfOrganization: "Ұйым түрі бойынша ЭҚЖЖ саны",
        numberOfOrganizationsByDistrict: "Аудандар бойынша ұйымдар саны",
        numberOfOrganizationsByOKED: "ЭҚЖЖ бойынша ұйым саны",
        numberOfOrganizationsByDimension: "Өлшемі бойынша ұйымның саны",
      },

      income: {
        period: "Кезең",
        paymentPlanForTheYear: "Бір жылға арналған төлемдер бойынша жоспар",
        yearlyPlanExecution: "Бір жылға арналған жоспардың орындалуы",
        annualIncomePlan: "Бір жылға арналған түсімдер жоспары",
        executionOfThePlanForThePeriod: "Жоспарды орындау кезеңі",
        budgetExecutionSchedule: "Бюджеттің атқарылу кестесі",
        executionPlan: "Орындау жоспары",
        execution: "Орындау",
        categoryName: " Санаттың атауы",
        nonPerformance: "Орындалмауы",
      },
      costs: {
        paymentPlanForTheYear: "Кезеңге төлемдер бойынша жоспар",
        ABP: "ББӘ (бюджеттік бағдарламалардың әкімшісі)",
      },
    },
    healthCare: {
      damuMed: {
        numberOfAccidents: "Жол-көлік оқиғаларының саны",
        numberOfSchools: "Мектептер саны",
        numberOfIndustrialFacilities: "Өндірістік нысандардың саны",
        numberOfQueues: "Кезектер саны",
        numberOfInjuries: "Жарақаттар саны",
        numberOfThermalInjuries: "Жылулық зақымданулар саны",
        numberOfPoisonings: "Уланулар саны",
        numberFromYesterday: "Кеше саны",
        numberFromToday: "Бүгінгі саны",
        numberOfRequests: "Өтініштер саны",
        numberOfPeopleWithARVI: "ЖРВИ жүгінгендер саны",
        numberOfTraumaVisits: "Жарақатпен жүгіну саны",
        numberOfVisitsToTheEmergencyRoom:
          "Қабылдау бөліміне түскен өтініштер саны",
        numberOfCasesWithARVI: "ЖРВИ жүгіну саны",
        numberOfQueuesInClinics: "Емханалардағы кезектер саны",
        numberOfFatalitiesFromInjuries:
          "Жарақаттанудан болатын өлім-жітім саны",
        total: "Жалпы саны",
      },
    },
    infrastructure: {
      repairAndConstruction: {
        responsible: "Жауапты",
        category: "Санаты",
        contractor: "Мердігер",
        showIntersections: "Қиылысты көрсету",
        redPlannedRenovation: "Қызыл - жоспарланған жөндеу",
        yellowInProgress: "Сары - жұмыста",
        greenDone: "Жасыл - орындалды",
        categoryOfWork: "Жұмыс санаты",
        street: "Көше",
        plot: "Учаскесі",
        contractorContacts: "Мердігердің байланыстары",
        warrantyPeriod: "Кепілдік кезеңі",
        start: "Басталуы",
        ending: "Аяқталуы",
        openingOfTheRoadway: "Жол төсемін ашу ",
        overlappingTheStreet: "Көшені жабу",
        comments: "Пікірлер",
        cost: "Құны",
        amountFor2021: "2021 жылға арналған сома",
        length: "Ұзындығы",
        whoAcceptedTheWork: "Жұмысты кім қабылдады",
      },
    },
    education: {
      childrenKindergartens: {
        elements: "Элементтер",
        openFilter: "Сүзгіні ашу",
        numberOfPreschoolOrganizations: "Мектепке дейінгі ұйымдардың саны",
        actualFullness: "Нақты толықтығы",
        deviation: "Ауытқу",
        queue: "Кезекте",
        issuedDirections: "Жолдама берілді",
        enrolled: "Есептелді",
        fullnessOfDDO: "МБҰ (мектепке дейінгі балалар ұйымдары) толықтығы",
        DDOType: "МБҰ түрі",
        preschoolMiniCenter: "Мектепке дейінгі шағын орталық",
        kindergarten: "Балабақша",
        nurseryGarden: "Бөбекжай",
        sanatoriumNurseryGarden: "Санаторлық бөбекжай",
        averageIndicatorsForPeriod: "Кезеңдегі орташа көрсеткіштер",
        languageOfInstruction: "Оқыту тілі",
        mixed: "Аралас",
        kazakh: "Қазақша",
        russian: "Орысша",
        durationOfStay: "Болу ұзақтығы",
        fullDayOfStay: "Толық күн болумен",
        partTime: "Жарты күн болуымен",
        withTwentyFourStay: "Тәулік бойы болуымен",
        queueStatus: "Кезектің статусы",
        fullnessStructure: "Толымдылық құрылымы",
        surplus: "Профицит",
        norm: "Норма",
        deficit: "Тапшылық",
        freePlaces: " Бос орындар",
        pivotTable: "Жиынтық кесте",
        name: "Атауы",
        address: "Мекенжайы",
        formOfInstitutions: "Мекеменің нысаны",
        fullness: "Толымдылығы",
        area: "Аудан",
        projectCapacity: "Жобалық қуаты",

        district: "Аудан",
        formOfInstitution: "Мекеме формасы",
        designCapacity: "Жоба қуаттылығы",
        DDOtype: "DDO типі",
      },
      pupilsBySchool: {
        generalShortageOfPlaces: "Орындардың жалпы тапшылығы",
        totalArea: "Жалпы алаңы",
        fullness: "Толықтығы",
        schoolType: "Мектеп түрі",
        secondarySchools: "Жалпы білім беретін мектептер",
        specializedSchoolsForGiftedChildren:
          "Дарынды балаларға арналған мамандандырылған мектептер",
        lyceum: "Лицей",
        gymnasiums: "Гимназиялар",
        structureOfSchoolsByShifts: "Ауысымдар бойынша мектептер құрылымы",
        firstShift: "Бірінші ауысым",
        secondShift: "Екінші ауысым",
        studentsByShifts: "Ауысымдар бойынша оқушылар",
        schoolsWithOneShift: "Бір ауысымды мектептер",
        schoolsWithTwoShifts: "Екі ауысымды мектептер",
        terrain: "Жергілікті жер",
        locality: "Елді мекен",
        view: "Түрі",
        typeOfOwnership: "Меншік нысаны",
        yearBuilt: "Салынған жылы",
        magnitudeOfTheDeficit: "Тапшылық шамасы",
        numberOfSchools: "Мектептер саны",
        projectCapacity: "Жобалық қуаты",
        schoolPopulationStructure: "Мектептердің толымдылық құрылымы",
        surplus: "Профицит",
        norm: "Норма",
        deficit: "Тапшылық",
        openFilter: "Сүзгіні ашу",
        pivotTable: "Жиынтық кесте",

        name: "Атауы",
        area: "Аудан",
        address: "Мекенжайы",
        languageOfInstruction: "Оқыту тілі",
        projectCapacityAll: "Жобалық қуаты*",
        actualFullness: "Нақты толықтығы",
      },
      infobus: {},
      monitoringSmi: {},
    },
  },
};

export default Kz;
